<template>
  <div id="StuPersonalAwardDetail">
    <van-cell-group style="margin-top:15px">
      <van-cell>
        <template #title>
          <span class="custom-title hidden-text">{{row.awardName}}</span>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text">
            <svg-icon icon-class="date" size="15" /> 获奖日期: {{parseTime(row.awardAt, "{y}-{m}-{d}")}}
          </div>
          <div class="custom-centent margin6 hidden-text">
            <svg-icon icon-class="unit" size="15" /> 颁发单位: {{row.awardDept}}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="获奖信息">
      <van-field v-model="row.stuName" readonly label="姓名" />
      <van-field v-model="row.stuNo" readonly label="学号" />
      <van-field v-model="row.awardName" readonly label="获奖名称" class="textarea-style" rows="2" type="textarea" />
      <van-field v-model="row.awardAt" readonly label="获奖时间" />
      <van-field v-model="row.awardDept" readonly label="颁发单位" />
      <van-field v-model="row.remark" readonly rows="3" class="textarea-style" label="说明" type="textarea" maxlength="300" />
    </van-cell-group>
    <van-cell-group title="证明附件" class="upload-file">
      <file-upload v-model="row.fileId" miz="STU_AWARD_PER" biz="storage" :multiple="3" :preview="true" list-type="list" :auto-upload="true" :max-size="1">
        <span slot="tip" class="upload-tips">
          支持上传任意文件,文件最大1M
        </span>
      </file-upload>
    </van-cell-group>
  </div>
</template>

<script>
import { statustag, parseTime } from "@/utils";
import fileUpload from "@/components/FileUpload";

export default {
  name: "StuPersonalAwardDetail",
  components: { fileUpload },
  dicts: ["xj_shzt"],
  data() {
    return {
      row: {
        id: null,
        stuNo: null,
        stuName: null,
        awardName: null,
        awardAt: null,
        remark: null,
        awardDept: null,
        fileId: [],
      },
    };
  },
  created() {
    let data = this.$route.query;
    this.row.fileId = data.fileId;
    this.$nextTick((e) => {
      this.row = data;
      if (data.awardAt) {
        this.row.awardAt = parseTime(data.awardAt, "{y}-{m}-{d}");
      }
    });
  },
  methods: {
    statustag,
    parseTime,
    onClickLeft() {
      this.row = {
        id: null,
        stuNo: null,
        stuName: null,
        awardName: null,
        awardAt: null,
        remark: null,
        awardDept: null,
        fileId: [],
      };
      this.$router.go(-1);
    },
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt
        .filter((item) => {
          return parseInt(item.value) === approvalStatus;
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
  },
};
</script>

<style  lang='scss' scoped>
@import "~@/assets/styles/student/award.scss";
.textarea-style {
  height: 100% !important;
}
.radius-st {
  position: absolute;
  right: 5%;
  top: 20%;
}
.van-cell__title {
  text-align: left;
  min-width: 70%;
}
.van-cell__title span {
  display: inline-block;
  text-align: left;
  word-break: break-all;
}
</style>
